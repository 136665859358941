import React from 'react'
import videoBg from '../assets/Code Hackathon Event Poster.mp4'

const Main = () => {

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${videoBg}"
          class="main"
        />,
      `}}>
      </div>
      <iframe
        title='googleForm'
        className='googleform'
        src="https://docs.google.com/forms/d/e/1FAIpQLSeBn4Obm6jtOgRi1qGRx2F2iQqiTknz2kLzxdF8ITifBPMxIQ/viewform?embedded=true"
      >Loading…</iframe>
    </div>
  )
}

export default Main;
